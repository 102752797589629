import moment from 'moment-timezone';
import { PUBLIC_SA_KEY, PUBLIC_SA_ALGORITHM, PUBLIC_SA_ADMIN_KEY } from '$env/static/public';
import { dev } from '$app/environment';

const KeyHeader = 'X-SA-Key';
const DateHeader = 'X-SA-Date';

const timeZone = 'UTC';
const timeFormat = 'YYYY-MM-DDTHH:mm:ss';

const sa_key = atob(PUBLIC_SA_KEY);
const sa_key_admin = PUBLIC_SA_ADMIN_KEY;
const sa_algorithm = PUBLIC_SA_ALGORITHM;

// generate SHA256 hash form string
export async function createSHA256Hash(data: string): Promise<string> {
	const encoder = new TextEncoder();
	const dataEncoded = encoder.encode(data);
	const hashBuffer = await crypto.subtle.digest(sa_algorithm, dataEncoded);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

// generate Signature headers for request - will sign request to api.
// if login is true, then will be used sa_key_admin for sign (as administrator)
export async function generateSignature(login: boolean = false) {
	const headers = new Headers();
	const SA_KEY_HASH = await createSHA256Hash(login ? sa_key_admin : sa_key);

	const date = moment().tz(timeZone).format(timeFormat) + 'Z';
	const saStr = `${SA_KEY_HASH}:${date}`;
	const saHash = await createSHA256Hash(saStr);

	headers.append(KeyHeader, saHash);
	headers.append(DateHeader, date);
	return headers;
}
